@use "@angular/cdk" as cdk;
@use "@ciphr/styles" as ciphr;

@import "@ciphr-design-system/foundations/ciphr-light";
@import "@angular/cdk/overlay-prebuilt.css";

@include cdk.a11y-visually-hidden();
@include ciphr.tooltip();

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
